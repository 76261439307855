import { useStaticQuery, graphql } from 'gatsby'

const useHomepage = () => {
    const { allPrismicHomepage } = useStaticQuery(graphql`
        query {
            allPrismicHomepage {
                edges {
                    node {
                        data {
                            cases {
                                case_type
                                case_title
                                case_link {
                                    url
                                }
                                case_link_title
                                case_media {
                                    url
                                    kind
                                }
                            }
                            case_section_header
                            contacts {
                                contact_email
                                contact_name
                                contact_phone
                                contact_role
                            }
                            contact_section_header
                            paragraph {
                                raw {
                                    text
                                    type
                                }
                            }
                            title
                        }
                    }
                }
            }
        }
    `)

    return allPrismicHomepage.edges[0].node.data
}

export default useHomepage
