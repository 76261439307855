import React from 'react'
import Fade from 'react-reveal/Fade'

const SectionHeader = ({ title }) => (
	<Fade>
		<h2 className="text-3xl md:text-4xl mb-12 lg:mb-16 px-5 md:px-8 lg:px-20">
			{title}
		</h2>
	</Fade>
)

export default SectionHeader
