import React, { useReducer, useContext } from 'react'
import { useSpring, useTransition, a, config } from 'react-spring'
import styled from 'styled-components'

const initialState = {
	show: false,
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SHOW':
			return { ...state, show: action.payload }
		default:
			return state
	}
}

const Context = React.createContext()

const O = ({ on }) => {
	const {
		store: { show },
	} = useContext(Context)

	const transitions = useTransition(show, null, {
		from: { opacity: 0, transform: 'scale(0)' },
		enter: { opacity: 1, transform: 'scale(1)' },
		leave: { opacity: 0, transform: 'scale(0)' },
		config: config.wobbly,
	})

	return transitions.map(
		({ item, key, props }) =>
			item && (
				<a.span
					className="inline-block absolute leading-none"
					key={key}
					style={{
						willChange: 'transform',
						...props,
					}}
				>
					o
				</a.span>
			)
	)
}

const Tada = () => {
	const {
		store: { show },
	} = useContext(Context)

	const { offset } = useSpring({
		from: { offset: -100 },
		to: { offset: show ? 100 : -100 },
	})

	return (
		<svg
			style={{ position: 'absolute', top: '-3px', left: '-14px' }}
			width="4rem"
			viewBox="0 0 305 122"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<a.path
				d="M301.399 64.4469L248.365 117.481M233.037 19.0696L204.391 88.2281M152.053 3V78.0009M71.5445 19.4523L100.133 88.4702M3 65.154L56.0336 118.188"
				stroke="#000000"
				stroke-width="10"
				strokeDasharray="100"
				strokeDashoffset={offset}
			/>
		</svg>
	)
}

const Cursor = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			viewBox="0 0 28 28"
			width="100%"
			height="100%"
		>
			<g filter="url(#filter0_d)">
				<path
					fill="#fff"
					d="M7 3v16l3.232-3.158L12.5 21.2l3.6-1.5-2.114-5.1H18.6L7 3z"
				></path>
				<path
					fill="#000"
					d="M8 16.6V5.4l8.2 8.2h-3.692l2.265 5.395-1.844.775-2.37-5.644L8 16.6z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_d"
					width="15.6"
					height="22.2"
					x="5"
					y="2"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="1"></feOffset>
					<feGaussianBlur stdDeviation="1"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow"
						result="shape"
					></feBlend>
				</filter>
			</defs>
		</svg>
	)
}

const Mouse = ({ cb }) => {
	const { dispatch } = useContext(Context)

	const [props] = useSpring(() => ({
		from: {
			left: '100%',
			top: '100%',
			transform: [0, 1],
		},
		to: async next => {
			await next({
				left: '0%',
				top: '17.5%',
				transform: [40, 1],
			})
			await next({
				left: '25%',
				top: '10%',
				transform: [0, 1],
			})
			await next({
				left: '-1%',
				top: '2%',
				transform: [15, 1],
			})
			await next({
				transform: [15, 0.8],
				config: config.wobbly,
			})
			dispatch({ type: 'SHOW', payload: true })
			await next({
				top: '4%',
				transform: [15, 1],
				config: config.wobbly,
			})
			await next({
				transform: [720, 1],
				config: config.default,
			})
			await next({
				top: '-75%',
				left: '25%',
				transform: [720, 1],
			})
		},
	}))

	return (
		<MouseContainer>
			<a.div
				className="absolute w-16 h-16"
				style={{
					...props,
					transform: props.transform.interpolate(
						(rotate, scale) =>
							`rotate(${rotate}deg) scale(${scale})`
					),
				}}
			>
				<Cursor />
			</a.div>
		</MouseContainer>
	)
}

const Word = () => {
	const {
		store: { show },
	} = useContext(Context)

	const { transform } = useSpring({
		from: { transform: 'translate3d(0%,0,0)' },
		to: { transform: `translate3d(${show ? 10.85 : 0}%,0,0)` },
		config: config.wobbly,
	})

	return (
		<a.span className="inline-block" style={{ transform }}>
			vanlig byrå
		</a.span>
	)
}

const Heading = () => {
	const [store, dispatch] = useReducer(reducer, initialState)

	return (
		<Context.Provider value={{ store, dispatch }}>
			<h1 className="flex flex-col lg:flex-row text-5xl md:text-6xl mb-16 md:mb-24 w-full ">
				<span className="pr-1 mr-3 leading-none mb-4">En helt</span>
				<span className="relative leading-none">
					<Mouse />
					<Tada />
					<O />
					<Word />
				</span>
			</h1>
		</Context.Provider>
	)
}

const MouseContainer = styled.div`
	position: absolute;
	pointer-events: none;
	z-index: 999;
	top: 0;
	left: 0;
	width: 800px;
	height: 1200px;
`

export default Heading
