import React from 'react'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import Heading from '../components/Heading'
import SectionHeader from '../components/SectionHeader'
import Case from '../components/Case'
import Contact from '../components/Contact'

import useHomepage from '../hooks/useHomepage'

const Index = () => {
    const {
        paragraph,
        case_section_header,
        cases,
        contact_section_header,
        contacts,
    } = useHomepage()

    return (
        <Layout>
            <section className="w-full pt-32 md:pt-64 mb-32 md:mb-64 px-5 md:px-8 lg:px-20">
                <Heading />
                {paragraph.raw.map((item, index) => (
                    <Fade key={index}>
                        <p className="md:w-9/12 lg:w-7/12 text-xl md:text-2xl text-gray-700 leading-relaxed text-black mb-12">
                            {item.text}
                        </p>
                    </Fade>
                ))}
            </section>

            {/*    <section className="mb-32 md:mb-64">
                <SectionHeader title={case_section_header} />
                {cases.map((item, index) => (
                    <Case
                        key={index}
                        type={item.case_type}
                        title={item.case_title}
                        link={item.case_link ? item.case_link.url : ''}
                        linkTitle={item.case_link_title}
                        media={item.case_media.url}
                        media_type={item.case_media.kind}
                    />
                ))}
            </section>*/}
            <section className="mb-32 md:mb-64">
                <SectionHeader title={contact_section_header} />
                <div className="flex flex-wrap lg:-mx-5">
                    {contacts.map((item, index) => (
                        <div
                            className="w-full lg:w-6/12 px-5 md:px-8 lg:px-20"
                            key={index}
                        >
                            <Contact
                                role={item.contact_role}
                                name={item.contact_name}
                                email={item.contact_email}
                                phone={item.contact_phone}
                            />
                        </div>
                    ))}
                </div>
            </section>
        </Layout>
    )
}

export default Index
