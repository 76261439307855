import React from 'react'
import Fade from 'react-reveal/Fade'

const Contact = ({ role, name, email, phone }) => (
	<Fade>
		<div className="lg:px-5 mb-20">
			<span className="text-gray-400 mb-1 block">{role}</span>
			<h2 className="text-3xl mb-4">{name}</h2>
			<a
				className="text-xl underline block mb-1"
				href={`mailto:${email}`}
			>
				{email}
			</a>
			<a className="text-xl underline block" href={`tel:${phone}`}>
				{phone}
			</a>
		</div>
	</Fade>
)

export default Contact
